<template>
  <div class="fixed-top">
    <ui-navbar
      ref="container"
      :height="NAVBAR_HEIGHT"
      class="phNavbar align-items-stretch"
    >
      <div class="d-flex align-items-center">
        <RouterLink
          :to="{ name: routes.booking.home }"
          data-test-id="logo"
        >
          <Logo class="d-flex" />
        </RouterLink>
      </div>
      <div
        ref="wrapperMenuItems"
        :style="`${initialMenuState ? 'overflow-x:auto' : ''}`"
        class="phNavbar_wrapperItems flex-grow-1 d-flex"
      >
        <template v-for="item in primaryMenuItems">
          <NavbarLink
            :key="item.props.testId"
            ref="navbarLinks"
            :test-id="item.props.testId"
            :link="item.props.link"
          />
        </template>
        <ui-dropdown
          v-if="isMoreButtonVisible"
          :placement="PLACEMENTS.bottomStart"
          :arrow-icon.prop="true"
          class="my-2 mr-4"
        >
          <div
            slot="trigger"
            class="emobg-font-weight-bold emobg-color-ink-light"
          >
            {{ $t('header.more') }}
          </div>
          <div slot="content">
            <div class="d-flex flex-column">
              <span
                v-for="item in secondaryMenuItems"
                :key="item.props.testId"
                class="p-2"
              >
                <Component
                  :is="item.component"
                  v-bind="item.props"
                />
              </span>
            </div>
          </div>
        </ui-dropdown>
      </div>
      <div class="profileSelector d-flex align-items-center ml-3">
        <ui-button
          :face="FACES.outline"
          :size="SIZES.large"
          :color="COLORS.primary"
          compact
          pill
          @clickbutton="toggleSideBar"
        >
          <ui-icon
            class="mx-2"
            :icon="ICONS[getCurrentProfileType]"
            :size="ICONS_SIZES.xLarge"
          />
          <div class="d-flex flex-column mr-4">
            <span class="emobg-font-weight-bold">{{ userFullName }}</span>
            <span class="emobg-font-small align-self-baseline">
              {{ $t(`header.profile_${getCurrentProfileType || PROFILE_TYPES.personal }`) }}
            </span>
          </div>
        </ui-button>
      </div>
      <SideBar
        :is-open="isSidebarVisible"
        @on:close="isSidebarVisible = false"
      />
    </ui-navbar>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import clone from 'lodash/clone';
import pullAt from 'lodash/pullAt';
import size from 'lodash/size';

import { aclService } from '@emobg/access-utils';
import { SPEED } from '@emobg/web-utils';
import { ICONS } from '@emobg/web-components';
// Utils
import cfg from '@/config';

// Components
import Logo from '@/components/Logo/Logo';
import SideBar from '@/components/Navbar/SideBar/SideBar';

import { getCompanyData, userHasCompany } from '@/stores/Company/CompanyMapper';
import { currentProfile, getCurrentProfileType, isBusinessProfileActive } from '@/stores/User/Profile/ProfileMapper';

// Store
import { companyAllowsCarpooling, userData, userFullName } from '@/stores/User/UserData/UserDataMapper';
import { getMyActiveBookingsCardinal, getMyActiveInterventionsCardinal } from '@/stores/Booking/BookingMapper';

// Constants
import { PROFILE_TYPES } from '@/constants/profiles.const';

import {
  ACCESS_TO_BACKOFFICE,
  INTERVENTION_BOOKING_TYPE,
  REGULAR_BOOKING_TYPE,
  REQUIRED_PERMISSION_COMPANY_ADMIN,
  REQUIRED_PERMISSION_COMPANY_ADMIN_CIRCLES,
  VIEW_BOOKINGS_COMPANY_EMPLOYEES,
  VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS,
  VIEW_PAYMENT_METHODS,
} from '@/constants/permissions';

// Routes
import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
import BookingRoutesNames from '@Bookings/router/routes-names';
import businessProfileRoutesNames from '@Profile/Business/router/routes-names';
import personalProfileRoutesNames from '@Profile/Personal/router/routes-names';

import NavbarLink from './NavbarLink';

export default {
  name: 'Navbar',

  components: {
    Logo,
    NavbarLink,
    SideBar,
  },

  inject: ['rootInjectedPaymentMethods'],

  data() {
    return {
      routes: {
        ...personalProfileRoutesNames,
        companyAdmin: {
          ...CompanyAdminRoutesNames,
        },
        booking: {
          ...BookingRoutesNames,
        },
        ...businessProfileRoutesNames,
      },
      initialMenuState: true,
      isMoreButtonVisible: true,
      isSidebarVisible: false,
      keyWrapperMenuItems: 0,
      primaryMenuItems: [],
      secondaryMenuItems: [],
      resizeListener: null,
      dropdownOpened: false,
      internalPaymentMethods: null,
    };
  },

  computed: {
    currentProfile,
    companyAllowsCarpooling,
    isBusinessProfileActive,
    getCompanyData,
    userHasCompany,
    getMyActiveBookingsCardinal,
    getMyActiveInterventionsCardinal,
    userData,
    getCurrentProfileType,
    userFullName,

    canSeeCompanyAdminMenu() {
      /**
       * If company has a payment method internal the tab is not showed so
       * we have to do a special condition with the permission of view_payment_method
       * to know if we have to show or not the company admin button
       */

      const userPermissions = [
        ...REQUIRED_PERMISSION_COMPANY_ADMIN,
        ...this.internalPaymentMethods ? [VIEW_PAYMENT_METHODS] : [],
      ];

      return this.isBusinessProfileActive && (aclService.hasUserPermissions(userPermissions)
        || aclService.hasPermissions(REQUIRED_PERMISSION_COMPANY_ADMIN_CIRCLES));
    },
    canViewMyInterventionsTab() {
      return aclService.hasUserPermissions(INTERVENTION_BOOKING_TYPE);
    },
    canViewBookAndMyBookingsTab() {
      return aclService.hasUserPermissions(REGULAR_BOOKING_TYPE);
    },
    canViewEmployeeBookings() {
      return (aclService.hasPermissions(VIEW_BOOKINGS_COMPANY_EMPLOYEES)
        || aclService.hasPermissions(VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS))
        && this.isBusinessProfileActive;
    },
    canViewAccessToBackoffice() {
      return aclService.hasUserPermissions(ACCESS_TO_BACKOFFICE)
        && !isEmpty(cfg.data.phoenixBackofficeUrl);
    },
    canViewCarpooling() {
      return this.isBusinessProfileActive && this.companyAllowsCarpooling;
    },
    allNavbarItems() {
      return [
        {
          component: NavbarLink,
          show: this.canViewBookAndMyBookingsTab,
          props: {
            testId: 'booking',
            link: {
              label: this.$t('header.new_booking'),
              exact: true,
              to: { name: this.routes.booking.home },
            },
          },
        },
        {
          component: NavbarLink,
          show: this.canViewBookAndMyBookingsTab,
          props: {
            testId: 'myBookings',
            link: {
              badge: this.getMyActiveBookingsCardinal,
              label: this.$t('header.my_booking'),
              to: { name: this.routes.booking.myBookings },
            },
          },
        },
        {
          component: NavbarLink,
          show: this.canViewEmployeeBookings,
          props: {
            testId: 'employeeBookings',
            link: {
              label: this.$t('business_profile.employee_bookings.tab'),
              to: { name: this.routes.companyAdmin.employeeBookingsList },
            },
          },
        },
        {
          component: NavbarLink,
          show: this.canViewMyInterventionsTab,
          props: {
            testId: 'myInterventions',
            link: {
              label: this.$t('header.my_interventions'),
              badge: this.getMyActiveInterventionsCardinal,
              to: { name: this.routes.booking.myInterventions },
            },
          },
        },
        {
          component: NavbarLink,
          show: this.canViewCarpooling,
          props: {
            testId: 'carpooling',
            link: {
              label: this.$t('main_menu.links.carpooling'),
              to: { name: this.routes.carpooling },
            },
          },
        },
        {
          component: NavbarLink,
          show: this.canSeeCompanyAdminMenu,
          props: {
            testId: 'companyAdmin',
            link: {
              label: this.$t('header.company_admin'),
              to: { name: this.routes.companyAdmin.companyAdmin },
            },
          },
        },
        {
          component: NavbarLink,
          show: this.canViewAccessToBackoffice,
          props: {
            testId: 'accessToBO',
            link: {
              useNativeTag: true,
              exact: true,
              href: cfg.data.phoenixBackofficeUrl,
              icon: ICONS.externalUrl,
              label: this.$t('header.access_to_backoffice'),
              target: '_blank',
            },
          },
        },
      ];
    },
    allowedMenuItems() {
      return filter(this.allNavbarItems, 'show');
    },
  },
  watch: {
    allNavbarItems(value, old) {
      if (!isEqual(value, old)) {
        this.adaptMenuItems();
      }
    },

    rootInjectedPaymentMethods: {
      handler(value) {
        this.internalPaymentMethods = value.internalPaymentMethods;
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    this.PROFILE_TYPES = PROFILE_TYPES;
    this.NAVBAR_HEIGHT = 64;
  },

  mounted() {
    this.adaptMenuItems();
    this.resizeListener = window.addEventListener('resize', throttle(this.adaptMenuItems, SPEED.medium));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    size,
    toggleSideBar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    resetMenuState() {
      this.initialMenuState = true;
      this.isMoreButtonVisible = true;
      this.primaryMenuItems = clone(this.allowedMenuItems);
      this.secondaryMenuItems = [];
    },
    adaptMenuItems() {
      this.resetMenuState();

      this.$nextTick(() => {
        this.isMoreButtonVisible = !isEmpty(this.$refs.moreButton)
          && !isEmpty(this.$refs.wrapperMenuItems)
          && !isEmpty(this.$refs.container);

        if (!this.isMoreButtonVisible) {
          return;
        }

        // Wait to the items to be rerendered
        const moreButtonMarginLeft = parseInt((window.getComputedStyle
          ? getComputedStyle(this.$refs.moreButton.$el)
          : this.$refs.moreButton.currentStyle).marginLeft, 10) || 0;

        let endOfAvailableSpace = this.$refs.moreButton.$el.offsetWidth + moreButtonMarginLeft;
        const availableSpace = this.$refs.wrapperMenuItems.offsetWidth;
        const itemsIndexToRemove = [];

        if (!isEmpty(this.$refs.navbarLinks)) {
          forEach(this.$refs.navbarLinks, (navbarLink, index) => {
            const element = navbarLink.$el;
            const styleOfElement = window.getComputedStyle
              ? getComputedStyle(element)
              : element.currentStyle;

            const marginLeft = parseInt(styleOfElement.marginLeft, 10) || 0;
            const elementWidth = element.offsetWidth + marginLeft;

            if (availableSpace >= endOfAvailableSpace + elementWidth) {
              endOfAvailableSpace += elementWidth;
            } else {
              itemsIndexToRemove.push(index);
            }
          });
        }

        const removedItems = pullAt(this.primaryMenuItems, itemsIndexToRemove);
        this.secondaryMenuItems = [
          ...this.secondaryMenuItems,
          ...removedItems,
        ];
        this.isMoreButtonVisible = !isEmpty(this.secondaryMenuItems);

        this.initialMenuState = false;
        this.keyWrapperMenuItems += 1;
      });
    },
  },
};
</script>
